@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
html {
    scroll-behavior: smooth;
  }
:root {
    --primary-color: #c654e9;
    --secondary-color: #2d117ce6;
    --tertiary-color: rgba(204, 111, 34, 0.3);
    --text-color: #000;
    --background-color: #FFF;
    --box-shadow-color: #333;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
#root {
    overflow-y: scroll;
    height: 100vh;
    scroll-snap-type: y mandatory;
  }
  
  #root > div {
    position: relative;
    height: 100vh;
    overflow: auto;
    padding: 32px;
    scroll-snap-align: start;
    z-index:2;
  }
  .hor {
    position:relative;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden !important;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    background-color: rgb(1, 6, 6);
    z-index:1 !important;
  }
  .hor::-webkit-scrollbar {
    height: 0;
    background: transparent;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    height:20px; width:200px; position:fixed; bottom:30px; left: calc( 50% - 100px);
  }
  
  .pagination-item {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0 8px;
    cursor: pointer;
  }
  
  .pagination-item.active {
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .hor-content {
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
  }
  
  .hor-section {
    scroll-snap-align: start;
    flex: 0 0 100vw;
  }
  .scroll-horizontal {
    scroll-snap-type: x mandatory;
  }
  

#root > footer {
    position: relative;
    z-index:2;
    height: auto;
    overflow: auto;
    padding: 32px;
    scroll-snap-align: end; /* Snap items to the start of the container */
}

#root > footer stylefooterlink{
cursor: pointer;
}
.fgBDdK {
    color: rgba(0, 0, 0, 0);
}

nav img {
    height: 100%;
    margin: 10px auto;
}

.sc-ikJzcn {
    font-size: 0.8rem;
}

.dbdAaF {
    background: var(--primary-color);
}

.styleFooterlink {
    cursor: pointer;
}

.container {
    display: flex;
    align-items: center;
    width: 100%;
}

.container.vertical {
    flex-direction: column;
}

.container.horizontal {
    flex-direction: row;
}

.box {
    margin: 15px;
    padding: 45px 20px;
    text-align: center;
    border-radius: 5px;
    width: 250px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    background: linear-gradient(45deg, var(--secondary-color), var(--tertiary-color));
}

.box .box {
    width: 200px;
    font-size: 16px;
    padding: 6px;
}

.box.inline {
    max-width: 200px;
    display: inline-block;
}

.arrow-container {
    z-index:100;
    width: 100%;
    height: 30px;
    overflow: visible;
    
}

.arrow {
    position: absolute;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid rgba(255,255,255, 0.5);
    animation: fallAndPulse 1.5s infinite linear;
}

.transaction {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: var(--text-color);
    text-align: center;
    margin: 0px 0;
}

.arrow-container.left,
.arrow-container.right {
    height: 100%;
    width: 30px;
    flex-direction: row;
}

.arrow-container.up .arrow {
    border-top: 20px solid transparent;
    border-bottom: 20px solid rgba(0, 0, 0, 0.5);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    animation: riseAndPulse 1.5s infinite linear; /* Change to riseAndPulse */
}

.arrow-container.left .arrow {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid rgba(0, 0, 0, 0.5);
    animation: slideAndPulseLeft 1.5s infinite linear;
}

.arrow-container.right .arrow {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid rgba(0, 0, 0, 0.5);
    animation: slideAndPulseRight 1.5s infinite linear;
}

.arrow.delay {
    animation-delay: 0.75s;
}

.up .arrow.delay {
    animation-delay: 0.75s;
}

.left .arrow.delay {
    animation-delay: 0.75s;
}

.right .arrow.delay {
    animation-delay: 0.75s;
}

@keyframes riseAndPulse {
    0% {
        transform: translateY(20px) scale(1);
        opacity: 0;
    }
    50% {
        transform: translateY(-15px) scale(1.3);
        opacity: 1;
    }
    100% {
        transform: translateY(-50px) scale(1);
        opacity: 0;
    }
}

@keyframes fallAndPulse {
    0% {
        transform: translateY(-20px) scale(1);
        opacity: 0;
    }
    50% {
        transform: translateY(15px) scale(1.3);
        opacity: 1;
    }
    100% {
        transform: translateY(50px) scale(1);
        opacity: 0;
    }
}

@keyframes slideAndPulseRight {
    0% {
        transform: translateX(-20px) scale(1);
        opacity: 0;
    }
    50% {
        transform: translateX(15px) scale(1.3);
        opacity: 1;
    }
    100% {
        transform: translateX(50px) scale(1);
        opacity: 0;
    }
}

@keyframes slideAndPulseLeft {
    0% {
        transform: translateX(50px) scale(1);
        opacity: 0;
    }
    50% {
        transform: translateX(15px) scale(1.3);
        opacity: 1;
    }
    100% {
        transform: translateX(-20px) scale(1);
        opacity: 0;
    }
}

.vertical .box {
    transform: perspective(500px) rotateX(20deg);
    transition: transform 0.5s;
    box-shadow: 0 5px 5px 1px var(--box-shadow-color);
}

.horizontal .box {
    transform: perspective(500px) rotateY(-20deg);
    transition: transform 0.5s;
}

.vertical .box:hover {
    transform: perspective(500px) rotateX(0deg);
    background: linear-gradient(45deg, var(--secondary-color), var(--secondary-color));
    box-shadow: 0 0px 0px 0px var(--background-color);
}

.horizontal .box:hover {
    transform: perspective(500px) rotateY(0deg);
    background: linear-gradient(45deg, var(--secondary-color), var(--secondary-color));
}

#pos .box {
    border-radius: 50%;
    height: 250px;
    margin: 0;
    background: rgba(0, 0, 0, 0.05);
    color: #333;
}

#pos .vertical .box {
    transform: perspective(500px) rotateX(55deg);
}

#pos .vertical .box:hover {
    transform: perspective(500px) rotateX(0deg);
    box-shadow: 0 0 1px #333;
    background: rgba(0, 0, 0, 0);
}

#pos #smartContracts {
    padding-top: 35%;
    width: 350px;
    height: 350px;
}

#pos #bitmosNetwork {
    padding-top: 25%;
    width: 300px;
    height: 300px;
}

#pos #bitmosNetwork {
    padding-top: 20%;
    width: 300px;
    height: 300px;
}

#pos .arrow-container {
    height: 1px;
}

#pos .box .box {
    height: 60px;
    width: 150px;
    padding-top: 10%;
    box-shadow: 0 0 1px #CCC;
}

#cosmos {
    position: relative;
    background: #000;
    overflow-x: hidden !important;
}

#cosmos h1,
#cosmos p {
    color: #FFF !important;
    text-shadow: 0 0 2px #000;
}

#cosmos .deECce {
    z-index: 2;
    position: relative;
}

#cosmos .sc-ezbkgU iYxMPu {
    opacity: 0.5;
}
.scroll-wrapper {
    overflow: hidden;
    width: 100%;
  }
  
  .scroll-container {
    display: flex;
    animation: scroll 20s linear infinite;
    width: calc(200%); /* Double the width to accommodate both sets of logos */
  }
  
  .network-item {
    display: inline-flex;
    align-items: center;
    padding: 10px;
  }
  
  .icon-container {
    margin-right: 10px;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  .scroll-container:hover {
    animation-play-state: paused;
  }
  