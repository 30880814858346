/* Define dark colors */


/* Style the browser window */
.browser-window {
    background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Courier New', Courier, monospace;

  width: 100%;
  max-width: 900px;
  margin: 20px auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  background: var(--bg-color);
  transform: perspective(900px) rotateY(-10deg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: #2d0c57;
}

.buttons {
  display: flex;
}

.address-bar {
  flex-grow: 1;
  margin: 0 10px;
  text-align: center;
  color:#fafafa;
}

.buttons div {
  display: inline-block;
  height: 15px;
  width: 15px;
  background-color: #640581;
  border-radius: 50%;
  margin-right: 5px;
}



/* Style the code block */
#codeBlock {
  background-color: #ffffff;
  color: var(--text-color);
  padding: 20px;
  overflow-x: auto;
  counter-reset: line-number;
  font-family:monospace;
}

#codeBlock code {
  display: block;
}

#codeBlock code div {
  width: 100%;
}

#codeBlock code div:before {
  counter-increment: line-number;
  content: counter(line-number);
  display: inline-block;
  width: 30px;
  padding-right: 10px;
  text-align: right;
  color: #080149;
}

.caret {
  display: inline-block;
  width: 1ch;
  height: 1em;
  background-color: #080808; /* A visible color */
  animation: blink 1s steps(2, start) infinite;
}

@keyframes blink {
  to {
    opacity: 0.2; /* The caret never fully disappears */
  }
}
