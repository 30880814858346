/* ContactForm.css */

.contact-form {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    resize: none;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #0056b3;
    outline: none;
  }
  
  textarea {
    min-height: 100px;
  }
  
  .submit-button {
    padding: 10px 15px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #003d80;
  }
  